import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/system",
  },
  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/system",
    redirect: "/system/project",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        name: "Project",
        path: "/system/project",
        component: () => import("@/views/system/project/index.vue"),
        meta: { title: "项目", tabbar: true },
      },
      {
        name: "Workbench",
        path: "/system/workbench",
        component: () => import("@/views/system/workbench/index.vue"),
        meta: { title: "工作台", tabbar: true },
      },
      {
        name: "Myself",
        path: "/system/myself",
        component: () => import("@/views/system/myself/index.vue"),
        meta: { title: "我的", tabbar: true },
      },
    ],
  },
  {
    path: "/app",
    component: () => import("@/layout/index.vue"),
    children: [
      {
        name: "sjjdetail",
        path: "sjjdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/shengjiangji/sjjdetail.vue"
          ),
        meta: { title: "施工升降机详情" },
      },
      {
        name: "qzjdetail",
        path: "qzjdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/qizhongji/qzjdetail.vue"
          ),
        meta: { title: "塔式起重机详情" },
      },
      {
        name: "xlptdetail",
        path: "xlptdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/xlpingtai/xlptdetail.vue"
          ),
        meta: { title: "卸料平台详情" },
      },
      {
        name: "lbfhdetail",
        path: "lbfhdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/lbfanghu/lbfhdetail.vue"
          ),
        meta: { title: "临边防护详情" },
      },
      {
        name: "jiancedetail",
        path: "jiancedetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/shengjiangji/jiancedetail.vue"
          ),
        meta: { title: "实时监测信息" },
      },
      {
        name: "qzjjiancedetail",
        path: "qzjjiancedetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/qizhongji/qzjjiancedetail.vue"
          ),
        meta: { title: "实时监测信息" },
      },
      {
        name: "xlptjiancedetail",
        path: "xlptjiancedetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/xlpingtai/xlptjiancedetail.vue"
          ),
        meta: { title: "实时监测信息" },
      },
      {
        name: "gaojingdetail",
        path: "gaojingdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/shengjiangji/gaojingdetail.vue"
          ),
        meta: { title: "设备告警信息" },
      },
      {
        name: "qzjgaojingdetail",
        path: "qzjgaojingdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/qizhongji/qzjgaojingdetail.vue"
          ),
        meta: { title: "设备告警信息" },
      },
      {
        name: "xlptgaojingdetail",
        path: "xlptgaojingdetail",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/xlpingtai/xlptgaojingdetail.vue"
          ),
        meta: { title: "设备告警信息" },
      },
      {
        name: "rlhistory",
        path: "rlhistory",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/shengjiangji/rlhistory.vue"
          ),
        meta: { title: "人脸识别记录" },
      },
      {
        name: "qzjrlhistory",
        path: "qzjrlhistory",
        component: () =>
          import(
            "@/views/app/projectInfo/ equipmenttest/qizhongji/qzjrlhistory.vue"
          ),
        meta: { title: "人脸识别记录" },
      },
      {
        name: "Personmanage",
        path: "personmanage",
        component: () => import("@/views/app/personmanage/index.vue"),
        meta: { title: "人员管理" },
      },
      {
        name: "Duty",
        path: "duty",
        component: () => import("@/views/app/duty/index.vue"),
        meta: { title: "考勤汇总" },
      },
      {
        name: "Environmentaltest",
        path: "environmentaltest",
        component: () => import("@/views/app/environmentaltest/index.vue"),
        meta: { title: "环境监测" },
        redirect: "/app/environmentaltest/tongji",
        children: [
          {
            name: "HuanJingTongji",
            path: "/app/environmentaltest/tongji",
            component: () => import("@/views/app/environmentaltest/tongji.vue"),
            meta: { title: "统计" },
          },
          {
            name: "Controlpanel",
            path: "/app/environmentaltest/controlpanel",
            component: () =>
              import("@/views/app/environmentaltest/controlpanel.vue"),
            meta: { title: "控制面板" },
            redirect: "/app/environmentaltest/controlpanel/controlpanelpage",
            children: [
              {
                name: "Controlpanelpage",
                path: "/app/environmentaltest/controlpanel/controlpanelpage",
                component: () =>
                  import("@/views/app/environmentaltest/controlpanelpage.vue"),
                meta: { title: "控制面板" },
              },
              {
                name: "Detailinfo",
                path: "/app/environmentaltest/controlpanel/detailinfo",
                component: () =>
                  import("@/views/app/environmentaltest/detailinfo.vue"),
                meta: { title: "详细信息" },
              },
            ],
          },
        ],
      },
      {
        name: "ProjectInfo",
        path: "projectInfo",
        component: () => import("@/views/app/projectInfo/index.vue"),
        meta: { title: "项目详情", cache: true },
        children: [
          {
            name: "deptInfo",
            path: "deptInfo",
            component: () => import("@/views/app/projectInfo/jiagouInfo.vue"),
          },
          {
            path: "workerInfo",
            component: () => import("@/views/app/projectInfo/workerInfo.vue"),
            meta: { title: "人员详情" },
          },
        ],
      },
      {
        name: "Anquanxunjian",
        path: "anquanxunjian",
        component: () => import("@/views/app/anquanxunjian/index.vue"),
        meta: { title: "安全巡检", cache: true },
      },
      {
        name: "qualitycheck",
        path: "qualitycheck",
        component: () => import("@/views/app/qualitycheck/index.vue"),
        meta: { title: "质量巡检", cache: true },
      },
      {
        name: "leadercheck",
        path: "leadercheck",
        component: () => import("@/views/app/leadercheck/index.vue"),
        meta: { title: "督导检查", cache: true },
      },
      {
        name: "inspection",
        path: "inspection",
        component: () => import("@/views/app/inspection/index.vue"),
        meta: { title: "移动巡检", cache: true },
      },
      {
        name: "inspectionCheck",
        path: "inspectionCheck",
        component: () => import("@/views/app/inspection/check/index.vue"),
        meta: { title: "开始巡检", cache: true },
      },
      {
        name: "inspectioninfo",
        path: "inspectioninfo",
        component: () => import("@/views/app/inspection/info.vue"),
        meta: { title: "移动路线巡检详情", cache: true },
      },
      {
        name: "inspectionsavePoint",
        path: "inspectionsavePoint",
        component: () => import("@/views/app/inspection/savepoint.vue"),
        meta: { title: "巡检点结果添加", cache: true },
      },
      {
        name: "inspectionsaveWay",
        path: "inspectionsaveWay",
        component: () => import("@/views/app/inspection/saveway.vue"),
        meta: { title: "巡检路线结果添加", cache: true },
      },
      {
        name: "Xunjiandetail",
        path: "xunjiandetail",
        component: () => import("@/views/app/anquanxunjian/xunjiandetail.vue"),
        meta: { title: "巡检详情" },
      },
      {
        name: "courselearn",
        path: "courselearn",
        component: () => import("@/views/app/courselearn/index.vue"),
        meta: { title: "课程培训", cache: true },
      },
      {
        name: "courseplay",
        path: "courseplay",
        component: () => import("@/views/app/courselearn/courseplay.vue"),
        meta: { title: "课程学习", cache: true },
      },
      {
        name: "epidemic",
        path: "epidemic",
        component: () => import("@/views/app/epidemic/index.vue"),
        meta: { title: "疫情防控", cache: true },
      },
      {
        name: "workerInfo",
        path: "workerInfo",
        component: () => import("@/views/app/epidemic/workerinfo.vue"),
        meta: { title: "工人详情记录", cache: true },
      },
      {
        name: "progressplan",
        path: "progressplan",
        component: () => import("@/views/app/progressplan/index.vue"),
        meta: { title: "进度管理", cache: true },
      },
      {
        name: "progressPlanInfo",
        path: "progressPlanInfo",
        component: () => import("@/views/app/progressplan/info.vue"),
        meta: { title: "进度详情", cache: true },
      },
      {
        name: "projectPlan",
        path: "projectPlan",
        component: () => import("@/views/app/projectplan/index.vue"),
        meta: { title: "项目计划", cache: true },
      },
      {
        name: "projectPlanInfo",
        path: "projectPlanInfo",
        component: () => import("@/views/app/projectplan/info.vue"),
        meta: { title: "计划详情", cache: true },
      },
      {
        name: "projectPlanTask",
        path: "projectPlanTask",
        component: () => import("@/views/app/projectplan/task.vue"),
        meta: { title: "子计划", cache: true },
      },
      {
        name: "projectPlanTaskInfo",
        path: "projectPlanTaskInfo",
        component: () => import("@/views/app/projectplan/taskinfo.vue"),
        meta: { title: "子计划详情", cache: true },
      },
      {
        name: "identify",
        path: "identify",
        component: () => import("@/views/app/courselearn/face/identify.vue"),
        meta: { title: "人脸认证", cache: true },
      },
      {
        name: "Xunjianadd",
        path: "xunjianadd",
        component: () => import("@/views/app/anquanxunjian/xunjianadd.vue"),
        meta: { title: "记录安全巡检", cache: true },
      },
      {
        name: "qualitycheckadd",
        path: "qualitycheckadd",
        component: () => import("@/views/app/qualitycheck/safe/index.vue"),
        meta: { title: "记录质量巡检", cache: true },
      },
      {
        name: "leadercheckadd",
        path: "leadercheckadd",
        component: () => import("@/views/app/leadercheck/safe/index.vue"),
        meta: { title: "记录督导巡检", cache: true },
      },
      {
        name: "areacheckInfo",
        path: "areacheckInfo",
        component: () => import("@/views/app/qualitycheck/info/index.vue"),
        meta: { title: "巡检详情", cache: true },
      },
      {
        name: "leaderCheckInfo",
        path: "leaderCheckInfo",
        component: () => import("@/views/app/leadercheck/info/index.vue"),
        meta: { title: "检查详情", cache: true },
      },
      {
        name: "areaAdd",
        path: "areaAdd",
        component: () => import("@/views/app/qualitycheck/safe/areaAdd.vue"),
        meta: { title: "新增区域" },
      },
      {
        name: "Xunjianareaadd",
        path: "xunjianareaadd",
        component: () => import("@/views/app/anquanxunjian/areaadd.vue"),
        meta: { title: "新增区域" },
      },
      {
        name: "Devicemanage",
        path: "devicemanage",
        component: () => import("@/views/app/devicemanage/index.vue"),
        meta: { title: "设备管理" },
      },
      {
        name: "Personaccess",
        path: "personaccess",
        component: () => import("@/views/app/personaccess/index.vue"),
        meta: { title: "人员出入" },
      },
      {
        name: "Personrecord",
        path: "personrecord",
        component: () => import("@/views/app/personrecord/index.vue"),
        meta: { title: "人员档案", cache: true },
      },
      {
        name: "PersonrecordInfo",
        path: "PersonrecordInfo",
        component: () =>
          import("@/views/app/personrecord/personrecordinfo.vue"),
        meta: { title: "人员档案" },
      },
      {
        name: "PersonrecordDetail",
        path: "PersonrecordDetail",
        component: () =>
          import("@/views/app/personrecord/personrecorddetail.vue"),
        meta: { title: "人员详情" },
      },
      {
        name: "Safecheck",
        path: "safecheck",
        component: () => import("@/views/app/safecheck/index.vue"),
        meta: { title: "安全隐患检查" },
        redirect: "/app/safecheck/tongji",
        children: [
          {
            name: "Tongji",
            path: "tongji",
            component: () => import("@/views/app/safecheck/tongji.vue"),
          },
          {
            name: "Checkrecord",
            path: "/app/safecheck/checkrecord",
            component: () => import("@/views/app/safecheck/checkrecord.vue"),
          },
        ],
      },
      {
        name: "safeCheckInfo",
        path: "safeCheckInfo",
        component: () => import("@/views/app/safecheck/info/index.vue"),
        meta: { title: "检查单详情" },
      },
      {
        name: "safeCheckSubmit",
        path: "safeCheckSubmit",
        component: () => import("@/views/app/safecheck/safe/index.vue"),
        meta: { title: "检查单添加" },
      },
      {
        name: "Visualmonitor",
        path: "visualmonitor",
        component: () => import("@/views/app/visualmonitor/index.vue"),
        meta: { title: "可视化监控" },
      },
      {
        name: "Monitorplay",
        path: "monitorplay",
        component: () => import("@/views/app/visualmonitor/monitorplay.vue"),
        meta: { title: "设备详情" },
      },
      {
        name: "Carmonitor",
        path: "carmonitor",
        component: () => import("@/views/app/carmonitor/index.vue"),
        meta: { title: "车辆出入" },
      },
      {
        name: "Clap",
        path: "clap",
        component: () => import("@/views/app/clap/index.vue"),
        meta: { title: "隐患随手拍", cache: true },
      },
      {
        name: "Clapdetail",
        path: "clapdetail",
        component: () => import("@/views/app/clap/clapdetail.vue"),
        meta: { title: "隐患随手拍详情" },
      },
      {
        name: "Clapadd",
        path: "clapadd",
        component: () => import("@/views/app/clap/clapadd.vue"),
        meta: { title: "隐患随手拍新增", cache: true },
      },
      {
        name: "Onlineexam",
        path: "onlineexam",
        component: () => import("@/views/app/onlineexam/index.vue"),
        meta: { title: "在线测试" },
      },
      {
        name: "Examquestions",
        path: "examquestions",
        component: () => import("@/views/app/onlineexam/examquestions.vue"),
        meta: { title: "在线测试" },
      },
      {
        name: "Cararchives",
        path: "cararchives",
        component: () => import("@/views/app/cararchives/index.vue"),
        meta: { title: "车辆档案" },
      },
      {
        name: "Areamanage",
        path: "areamanage",
        component: () => import("@/views/app/areamanage/index.vue"),
        meta: { title: "区域管理" },
      },
      {
        name: "Disciplinesafe",
        path: "disciplinesafe",
        component: () => import("@/views/app/disciplinesafe/index.vue"),
        meta: { title: "纪检安全" },
      },
      {
        name: "Disciplinestudy",
        path: "disciplinestudy",
        component: () => import("@/views/app/disciplinesafe/study.vue"),
        meta: { title: "纪检学习", cache: true },
      },
      {
        name: "Disciplinerecord",
        path: "disciplinerecord",
        component: () =>
          import("@/views/app/disciplinesafe/disciplinerecord.vue"),
        meta: { title: "纪检学习" },
      },
      {
        name: "Disciplinestudydetail",
        path: "disciplinestudydetail",
        component: () => import("@/views/app/disciplinesafe/studydetail.vue"),
        meta: { title: "纪检详情", cache: true },
      },
      {
        name: "Disciplinetest",
        path: "disciplinetest",
        component: () =>
          import("@/views/app/disciplinesafe/disciplinetest.vue"),
        meta: { title: "专项答题" },
      },
      {
        name: "Killrecord",
        path: "killrecord",
        component: () => import("@/views/app/killrecord/index.vue"),
        meta: { title: "消杀记录" },
      },
      {
        name: "DisinfectInfo",
        path: "disinfectInfo",
        component: () => import("@/views/app/killrecord/disinfectInfo.vue"),
        meta: { title: "消杀详情" },
      },
      {
        name: "Disinfectadd",
        path: "disinfectadd",
        component: () => import("@/views/app/killrecord/disinfectadd.vue"),
        meta: { title: "消杀添加" },
      },
      {
        name: "Visitorrecord",
        path: "visitorrecord",
        component: () => import("@/views/app/visitorrecord/index.vue"),
        meta: { title: "访客记录" },
      },
      {
        name: "syncPersonRowFun",
        path: "syncPersonRowFun",
        component: () =>
          import("@/views/app/personrecord/syncPersonRowFun.vue"),
        meta: { title: "下发记录" },
      },
      // {
      //   name: "videoInspection",
      //   path: "videoInspection",
      //   component: () => import("@/views/app/videoInspection/index.vue"),
      //   meta: { title: "视频巡检", cache: true },
      // },
      // {
      //   name: "videoInspectionAdd",
      //   path: "videoInspectionAdd",
      //   component: () => import("@/views/app/videoInspection/safe/index.vue"),
      //   meta: { title: "新增视频巡检", cache: true },
      // },
      // {
      //   name: "videoInspectionEnd",
      //   path: "videoInspectionEnd",
      //   component: () => import("@/views/app/videoInspection/safe/end.vue"),
      //   meta: { title: "新增视频巡检", cache: true },
      // },
      // {
      //   name: "videoInspectionInfo",
      //   path: "videoInspectionInfo",
      //   component: () => import("@/views/app/videoInspection/info/index.vue"),
      //   meta: { title: "巡检详情", cache: true },
      // },

      {
        name: "personInfo",
        path: "personInfo",
        component: () => import("@/views/app/personInfo/info.vue"),
        meta: { title: "人员信息详情" },
      },
    ],
  },
  {
    name: "Passworld",
    path: "/passworld",
    component: () => import("@/views/mine/passworld/index.vue"),
    meta: { title: "修改密码" },
  },
  {
    name: "Myproject",
    path: "/myproject",
    component: () => import("@/views/mine/myproject/index.vue"),
    meta: { title: "我的项目" },
  },
  {
    name: "Mynotice",
    path: "/mynotice",
    component: () => import("@/views/mine/mynotice/index.vue"),
    meta: { title: "通知管理" },
  },
  {
    name: "Noticedetail",
    path: "/noticedetail",
    component: () => import("@/views/mine/mynotice/noticedetail.vue"),
    meta: { title: "通知管理" },
  },
  {
    name: "Myduty",
    path: "/myduty",
    component: () => import("@/views/mine/myduty/index.vue"),
    meta: { title: "我的考勤" },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
